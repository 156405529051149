import Linkbar from './Linkbar';
import Footer from './Footer';
import Page from './Page'
import Admin from './Admin'
import React, { useState, useEffect, useCallback } from 'react';
//import useWebSocket from 'react-use-websocket';
import {
  HashRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import styles from './App.module.css';

function App(props){
  const valuesLocation = "/values";
  const [values, setValues] = useState({});
  const [lastSavedValues, setLastSavedValues] = useState(null);
  const [pageTitlePrefix, setPageTitlePrefix] = useState("");

  // whether or not the user is logged in and showing administrative controls
  const [adminAvailable, setAdminAvailable] = useState(false);
  const [adminVisible, setAdminVisible] = useState(false);

  //const [socketUrl, setSocketUrl] = useState(null);
  // could also pull out sendMessage, readyState
  //const { lastMessage } = useWebSocket(socketUrl);

  // this fetches values.json from the remote server
  const reloadValuesCallback = useCallback(() => {
    let valuesURL = process.env.REACT_APP_API_HOST+valuesLocation;
    console.log(`Fetching values from ${valuesURL}`);
    fetch(valuesURL, {credentials: 'include'})
    .then(res => res.json())
    .then(
      (result) => {
        setValues(result);
        setLastSavedValues(structuredClone(result));
        const adminAuthenticated = "auth" in result && result["auth"]["authenticated"];
        setAdminAvailable(adminAuthenticated);
        if(!adminAuthenticated){
          setAdminVisible(false);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }, [valuesLocation]);

  useEffect(() => {
    reloadValuesCallback();
  }, [valuesLocation, reloadValuesCallback]);

  useEffect(() => {
    document.title = (pageTitlePrefix ? pageTitlePrefix + " > " : "") + "Moraine Air Park, Inc.";
  }, [pageTitlePrefix]);

  /*
  
  Disabled because websockets don't work on parrot
  
  // this sets up the websocket
  useEffect(() => {
    if("api" in values){
      let port = values["api"]["port"];
      let hostname = (values["api"]["hostname"]) ? values["api"]["hostname"] : window.location.hostname;
      let pathPrefix = (values["api"]["path_prefix"]) ? values["api"]["path_prefix"] : "";
      let portString = (port === "") ? "" : ":"+port;
      let websocketURL = `ws://${hostname}${portString}/${pathPrefix}/sock`;
      console.log(`Creating websocket to ${websocketURL}`);
      setSocketUrl(websocketURL);
    }
  }, [values]);

  // This receives messages from the websocket
  useEffect(() => {
    if (lastMessage !== null) {
      console.log(lastMessage);
    }
  }, [lastMessage]);
  */

  const adminElement = (
    <Admin 
      reloadValuesCallback={reloadValuesCallback} 
      setPageTitlePrefix={setPageTitlePrefix} 
      values={values} 
      lastSavedValues={lastSavedValues} 
      setValues={setValues} 
      adminAvailable={adminAvailable} 
    />);
  
    const pageElement = (
      <Page 
        setPageTitlePrefix={setPageTitlePrefix} 
        values={values} 
        adminVisible={adminVisible} />
    );

  // The last route is a workaround for the fact that an empty route (http://moraineairpark.com/) does not
  // work as expected. Expected behavior would be to match the dynamic Route, with params.route === "",
  // but actual behavior is that the Route does not match at all.    
  return (
    <Router>
      <div className={styles["Root"]}>
        <div className={styles["MainLayout"]}>
          <div className={styles["Top"]}>
            <div className={styles["Top-Left"]}><img className={styles["Airport-Logo"]} src={process.env.REACT_APP_FILE_PREFIX+"/moraine-airpark-logo.jpg"} alt="Moraine Airport Logo" /></div>
            <div className={styles["Link"]}>
              <Linkbar values={values} pageTitlePrefix={pageTitlePrefix} />
            </div>
          </div>
          <div className={styles["Content-Root"]}>
            <div className={styles["Page"]}>
              <Routes>
                  <Route path="admin/token/:token" key="admin" element={adminElement} />
                  <Route path="admin/:section" key="admin" element={adminElement} />
                  <Route path="admin" key="admin" element={adminElement} />
                  <Route path=":route" key="primary" element={pageElement} />
                  <Route path=":route/*" key="with_params" element={pageElement} />
                  <Route path="/" key="default" element={pageElement} />
              </Routes>
            </div>
            <div id='App-Footer-ID' className={styles["Footer"]} >
              <Footer values={values} reloadValuesCallback={reloadValuesCallback} adminVisible={adminVisible} />
            </div>
          </div >
        </div>
        {adminAvailable && 
          <div className={styles["AdminVisibleControls"]} onClick={()=>{setAdminVisible(!adminVisible)}} >
            <i className={"demo-icon " + (adminVisible ? "icon-lock-open-alt" : "icon-lock")} /> 
          </div>
        }
      </div>
    </Router>
  );
}

export default App;
