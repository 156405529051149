//import TextField from '@mui/material/TextField';
//import Button from '@mui/material/Button';
import styles from './FileTable.module.css';
import FileObject from './FileObject';
import FileUploader from './FileUploader';
import React, { useState, useCallback, useEffect } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function FileTable(props){
  const [files, setFiles] = useState([]);
  const [sortProperty, setSortProperty] = useState('newest');
  const [error, setError] = useState("");

  // this fetches values.json from the remote server
  const reloadListCallback = useCallback(() => {
    let valuesURL = process.env.REACT_APP_API_HOST+"/files";
    console.log(`Fetching file list from ${valuesURL}`);
    fetch(valuesURL, {credentials: 'include'})
    .then(res => res.json())
    .then(
      (result) => {
        setFiles(result["files"]);
      },
      (error) => {
        console.error(error);
        setError(`Could not fetch file list. ${error}`);
      }
    );
  }, []);

  useEffect(() => {
    reloadListCallback();
  }, [reloadListCallback]);

  const file_sort_functions = {
    "name" : (a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0),
    "newest" : (a,b) => (a.ctime > b.ctime) ? -1 : ((b.ctime > a.ctime) ? 1 : 0)
  }
  const file_sort_function = file_sort_functions[sortProperty];

  return (
    <div className={styles["Root"]}>
      <FileUploader reloadListCallback={reloadListCallback} setError={setError} />
      <div className={styles["SortButton"]}>
        <ToggleButtonGroup
          color="primary"
          value={sortProperty}
          exclusive
          onChange={ (ee,xx) => setSortProperty(xx) }
          aria-label="Platform">
            <ToggleButton value="name">Name</ToggleButton>
            <ToggleButton value="newest">Newest</ToggleButton>
        </ToggleButtonGroup>
      </div>
      {
        files.sort(file_sort_function).map( (file, ii) => (
          <div key={"file_object_"+ii+"_"+file.name}>
            <FileObject fileObj={file} reloadListCallback={reloadListCallback} setError={setError} />
          </div>
        ))
      }
      {error !== "" && <div className={styles["ErrorRoot"]} onClick={()=>{setError("")}}>{error} <span className={styles["Acknowledge"]}>Acknowledge</span></div> }
    </div>
  );
}


export default FileTable;
