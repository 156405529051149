import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import styles from './TokenTable.module.css';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import Popover from '@mui/material/Popover';

function TokenTable(props){
  const [addEnabled, setAddEnabled] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [popAnchor, setPopAnchor] = useState(null);
  const [popText, setPopText] = useState("");

  const myName = props.values.auth["name"];

  const newNameRef = useRef(null);
  const newTokenRef = useRef(null);
  const newAdminRef = useRef(null);

  const reloadTokensCallback = useCallback(() => {
    let url = process.env.REACT_APP_API_HOST+"/tokens";
    console.log(`Fetching tokens from ${url}`);
    fetch(url, {credentials: 'include'})
    .then(res => res.json())
    .then(
      (result) => {
        setTokens(() => result["tokens"]);
      },
      (error) => {
        console.error(error);
      }
    );
  }, []);

  const saveCallback = useCallback(() => {
    const url = process.env.REACT_APP_API_HOST+"/tokens";
    const body = {
      "tokens" : tokens
    }
    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then(res => res.json())
    .then(
      (result) => {
      },
      (error) => {
        console.error(error);
      }
    );
  }, [tokens]);

  useEffect(() => {
    // do not save if this is the initial page load, or if we have erased all tokens
    if(tokens.length > 0){
      saveCallback();
    }
  }, [tokens, saveCallback]);

  useEffect(() => {
    reloadTokensCallback();
  }, [reloadTokensCallback]);

  const updateAddEnabled = useCallback(() => {
    setAddEnabled(newNameRef.current.value !== "");
  }, [setAddEnabled]);

  return (
    <div className={styles["TokenRoot"]}>
      <div className={styles["TokeTable"]}>
        <table className={styles["AdminTableTop"]}>
          <tbody>
            <tr key="row-header">
              <td className={styles["AdminTableHeader"]}>
                Name
                <i className="demo-icon icon-help-circled" onClick={(ee) => {setPopText("The human friendly alias for this code. Appears in log files."); setPopAnchor(ee.currentTarget);}} />
              </td>
              <td className={styles["AdminTableHeader"]}>
                Type
                <i className="demo-icon icon-help-circled" onClick={(ee) => {setPopText("Admin or normal. Admin tokens can view and create other tokens. Normal can not."); setPopAnchor(ee.currentTarget);}} />
              </td>
              <td className={styles["AdminTableHeader"]}>
                Token
                <i className="demo-icon icon-help-circled" onClick={(ee) => {setPopText("The token in a convenient hyperlink form, for distributing to maintainers."); setPopAnchor(ee.currentTarget);}} />
              </td>
              <td className={styles["AdminTableHeader"]}>&nbsp;</td>
            </tr>
            {
              tokens.sort().map( (token, ii) => (
                <tr key={"token"+ii}>
                  <td className={styles["AdminTableCell"]}>
                    {token["name"]}
                  </td>
                  <td className={styles["AdminTableCell"]}>
                    {token["admin"] ? "Admin" : "Normal"}
                  </td>
                  <td className={styles["AdminTableCell"] + " " + styles["TokenCell"]}>
                    <Link to={"/admin/token/"+token["token"]}>{token["token"]}</Link>
                  </td>
                  <td className={styles["AdminTableCell"]}>
                    <div className={styles["PageButtons"]}>
                      <div>
                        <Button 
                          id="del-field"
                          label=""
                          className={styles["MuiShrinkableButton"]}
                          onClick={
                            (ee) => {
                              setTokens(
                                (xx) => {
                                  let yy = [...xx];
                                  yy.splice(ii, 1);
                                  return yy;
                                }
                              )
                            }
                          }
                          disabled={token["name"] === myName}
                          variant="outlined">Del</Button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <div className={styles["CreateToken"]}>
        Create Token
      </div>
      <div className={styles["AddTokenRoot"]}>
        <div className={styles["AdminTableCell"]}>
          <TextField 
            id="field-field" 
            inputRef={newNameRef}
            onChange={updateAddEnabled}
            label="Name"
            variant="outlined" 
            sx={{width: "140px"}}
            size="small" />
        </div>
        <div className={styles["AdminTableCell"]}>
          <TextField
            id="add-admin"
            select
            size="small"
            inputRef={newAdminRef}
            defaultValue={false}
          >
            <MenuItem key="Normal" value={false}>Normal</MenuItem>
            <MenuItem key="Admin" value={true}>Admin</MenuItem>
          </TextField>
        </div>
        <div className={styles["AdminTableCell"]}>
          <TextField 
            id="field-text" 
            inputRef={newTokenRef}
            label=""
            variant="outlined"
            placeholder="Auto generate token"
            sx={{width: "300px"}}
            size="small" />
        </div>
        <div className={styles["AdminTableCell"]}>
          <Button 
            id="add-token"
            label=""
            onClick={!addEnabled ? ()=>{} :
              (ee) => {
                setTokens(
                  (xx) => {
                    let yy = [...xx];

                    yy.push(
                      {
                        "name" : newNameRef.current.value,
                        "token" : newTokenRef.current.value === "" ? uuidv4() : newTokenRef.current.value,
                        "admin" : newAdminRef.current.value
                      }
                    );
                    newNameRef.current.value = "";
                    newTokenRef.current.value = "";
                    setAddEnabled(false);
                    return yy;
                  }
                )
              }
            } 
            variant={addEnabled ? "contained" : "outlined"} disabled={!addEnabled}>Add</Button>
        </div>
      </div>
      <div className={styles["TokenWarningTop"]}>
        <div className={styles["TokenWarning"]}>
          Do not use passwords for tokens! Tokens are not managed in a secure way.
        </div>
      </div>
      <Popover id="popover-name-help" open={popAnchor !== null} anchorEl={popAnchor}
        onClose={()=>setPopAnchor(null)} anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}>
        <div className={styles["Popup"]}>
          {popText}
        </div>
      </Popover>
  </div>
  );
}

export default TokenTable;
