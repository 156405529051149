import styles from './PlaceVideo.module.css';
import React from "react";

function PlaceVideo(props) {

  const src = (props.src ? props.src : "");
  const width = (props.width ? props.width : "100%");
  const height = (props.height ? props.height : "550px");
  const showDownloadLink = (props.showDownloadLink && props.showDownloadLink.toLowerCase() === "true" ? true : false);
  const paddingTop = (props.paddingTop ? props.paddingTop : "20px");
  const paddingBottom = (props.paddingBottom ? props.paddingBottom : "10px");
  const caption = (props.caption ? props.caption : "");

  const types = {
    "mp4" : "video/mp4",
    "mov" : "video/mov"
  }

  const fileExtension = src.split('.').pop().toLowerCase();

  const videoType = (fileExtension in types) ? types[fileExtension] : null;

  return (
    <span className={styles["Root"]} style={{ paddingTop: `${paddingTop}`, paddingBottom: `${paddingBottom}` }}>
      <span className={styles["Root-Centered"]}>
        <span className={styles["Video-Span"]}>
            <span>
            { videoType && 
              <video controls className={styles["Video"]} alt="" 
                style={{
                  maxWidth: `${width}`,
                  maxHeight: `${height}` 
                }}>
                <source src={process.env.REACT_APP_FILE_PREFIX + "/" + src} type={fileExtension[types]} />
              </video>}

              { !videoType && 
                <span className={styles["Error"]} style={{width: width, height: height}}>
                  Sorry, can not play {src} because the file extension is not recognized. Recognized extensions are mp4, mov.
              </span>}

          </span>
        </span>
        {(caption &&
          <span className={styles["Caption"]}>{caption}</span>
        )}          
        {(showDownloadLink &&
          <span className={styles["Download-Link"]}><a target="_blank" rel="noreferrer" href={process.env.REACT_APP_FILE_PREFIX + "/" + src} ><i className="demo-icon icon-download" />Download Video</a></span>
        )}
      </span>
    </span>
  );

}

export default PlaceVideo;
