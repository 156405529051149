import styles from './Banner.module.css';
import React from "react";

class Banner extends React.Component {

  render() {

    let src = (this.props.src ? this.props.src : "");
    let backgroundPosition = (this.props.backgroundPosition ? this.props.backgroundPosition : "center");
    let height = (this.props.height ? this.props.height : "150px");
    let credit = (this.props.credit ? this.props.credit : "");
    let creditColor = (this.props.creditColor ? this.props.creditColor : "#FFF8");

    return (
      <span className={styles["Root"]}
          style={{
            backgroundImage: `url(${process.env.REACT_APP_FILE_PREFIX + "/" + src})`,
            backgroundPosition: `${backgroundPosition}`,
            height: `${height}`,
            backgroundRepeat: "no-repeat" }}>
        <span className={styles["Credit"]} style={{color: creditColor}}>{credit}</span>
      </span>
    );
  }

}

export default Banner;
