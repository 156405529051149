import styles from './EditPage.module.css';
import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function EditPage(props){
  const setSaveEnabled = props.setSaveEnabled;
  const content = props.content;
  const setContent = props.setContent;
  const lastSavedContent = props.lastSavedContent;

  useEffect(() => {
    setSaveEnabled(content !== lastSavedContent);
  }, [content, lastSavedContent, setSaveEnabled]);

  return (
    <div className={styles["Root"]}>
      <div className={styles["EditArea"]}>
        <TextField
          id="outlined-textarea"
          label=""
          placeholder="Content"
          value={content}
          onChange={
            (ee) => {
              setContent(ee.target.value)
            }
          }
          fullWidth
          multiline
        />
      </div>
      <div className={styles["QuickRefRoot"]}>
        <div><Link to="/reference-markup" className={styles["QuickRefLink"]}>Markup Quick Ref</Link></div>
        <div><Link to="/reference-slideshow" className={styles["QuickRefLink"]}>Slideshow Quick Ref</Link></div>
      </div>
    </div>
  )
}

export default EditPage;
