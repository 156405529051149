import styles from './PlaceImage.module.css';
import React from "react";

function PlaceImage(props) {

  const src = (props.src ? props.src : "");
  const width = (props.width ? props.width : "100%");
  const height = (props.height ? props.height : "550px");
  const showDownloadLink = (props.showDownloadLink && props.showDownloadLink.toLowerCase() === "true" ? true : false);
  const link = (props.link && props.link.toLowerCase() === "true" ? true : false);
  const paddingTop = (props.paddingTop ? props.paddingTop : "20px");
  const paddingBottom = (props.paddingBottom ? props.paddingBottom : "10px");
  const caption = (props.caption ? props.caption : "");

  const imageJSX = 
    <img className={styles["Image"]} alt="" src={process.env.REACT_APP_FILE_PREFIX + "/" + src} 
    style={{
      maxWidth: `${width}`,
      maxHeight: `${height}` 
    }} />;

  return (
    <span className={styles["Root"]} style={{ paddingTop: `${paddingTop}`, paddingBottom: `${paddingBottom}` }}>
      <span className={styles["Root-Centered"]}>
        <span className={styles["Image-Span"]}>
          {(link &&
              <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_FILE_PREFIX + "/" + src} >{imageJSX}</a>
          )}
          {(!link &&
              <span>{imageJSX}</span>
          )}
        </span>
        {(caption &&
          <span className={styles["Caption"]}>{caption}</span>
        )}          
        {(showDownloadLink &&
          <span className={styles["Download-Link"]}><a target="_blank" rel="noreferrer" href={process.env.REACT_APP_FILE_PREFIX + "/" + src} ><i className="demo-icon icon-download" />Download Image</a></span>
        )}
      </span>
    </span>
  );

}

export default PlaceImage;
