import styles from './Field.module.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState, useCallback, useRef } from 'react';

function FieldValue(props){
  const [open, setOpen] = useState(false);
  const valueRef = useRef(null);

  const fields = props.fields;
  const field = props.field;
  const adminVisible = props.adminVisible;
  const reloadValuesCallback =  props.reloadValuesCallback;

  const renderText = props.renderText === undefined ? (xx) => xx : props.renderText;

  const saveCallback = useCallback(() => {
    const url = process.env.REACT_APP_API_HOST+"/update-values";
    console.log(`Saving page to ${url}`);
    const body = {
      "updates" : {
        "fields" : {
          [field] : {
            "value" : valueRef.current.value,
            "updated" : Math.round(Date.now()/1000)
          }
        }
      }
    }
    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then(res => res.json())
    .then(
      (result) => {
        reloadValuesCallback();
        setOpen(false);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [field, valueRef, reloadValuesCallback]);

  const value = (fields && field in fields) ? fields[field]["value"] : "";

  //<i onClick={() => {setOpen(true)}} className={"demo-icon icon-wrench " + styles["EditButton"]} />

  return (
    <div className={styles["Root"]}>
      {adminVisible && (
        <div className={styles["AdminRoot"]}>
          <div className={styles["AdminBackground"]}>
            {renderText(value)}
            <div onClick={() => {setOpen(true)}} className={styles["AdminPopupOverlay"]}  />
          </div>
          <div>
            <Dialog open={open}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "600px",  // Set your width here
                  },
                },
              }}
              >
              <DialogTitle>Edit {field}</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label=""
                  type="text"
                  fullWidth
                  variant="standard"
                  defaultValue={value}
                  inputRef={valueRef}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => {setOpen(false)}}>Cancel</Button>
                <Button onClick={saveCallback}>Save</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      )}
      {!adminVisible && (<div>{renderText(value)}</div>)}
    </div>
  )
}

function FieldUpdated(props){
  const fields = props.fields;
  const field = props.field;

  const renderText = props.renderText === undefined ? (xx) => xx : props.renderText;

  const updated = (fields && field in fields) ? fields[field]["updated"] : "";

  return (
    <div className={styles["Root"]}>
      {renderText(updated)}
    </div>
  )
}

export {FieldValue, FieldUpdated};
