import styles from './HtmlPage.module.css';
import React from "react";

class HtmlPage extends React.Component {
  render() {
    //const linkbar = this.props.linkbar;
    //const pageChangeFunction = this.props.pageChangeFunction;
    //const page = this.props.page;

    let rawHTML = this.props.content;

    return (
      <div className={styles["Root"]}>
        <div className={styles["Embedded-Content"]} dangerouslySetInnerHTML={{__html: rawHTML}}></div>
      </div>
    );
  }

}

export default HtmlPage;
