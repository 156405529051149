import styles from './Footer.module.css';
import React from "react";
import { FieldValue, FieldUpdated } from './Field';

function Footer(props){

  // replaceAll is coded here because browser compatability on this function is lacking in 9/2023
  const replaceAll = (text, search, replacement) => text.split(search).join(replacement);

  const fields = props.values.fields;

  return (
    <div className={styles["Root"]}>
      <div className={styles["Airport-ID-Root"]}>
        <FieldValue 
          fields={fields} field="id_text"
          setValues={props.setValues} 
          adminVisible={props.adminVisible} 
          reloadValuesCallback={props.reloadValuesCallback} />         
      </div>
      <div className={styles["Embedded-Content"]}>
        <div className="footer-left">
            <div>
              <FieldValue 
                fields={fields} field="attended_text"
                setValues={props.setValues} 
                adminVisible={props.adminVisible} 
                reloadValuesCallback={props.reloadValuesCallback} /> 
            </div>
            <div>
              <FieldValue 
                fields={fields} field="address_text"
                setValues={props.setValues} 
                adminVisible={props.adminVisible} 
                reloadValuesCallback={props.reloadValuesCallback} 
                renderText={(xx) => <a target="_blank" rel="noreferrer" href={"https://www.google.com/maps/place/"+replaceAll(xx, " ","+")}>{xx}</a> } />
            </div>
        </div>
        <div className="footer-right">
            <div>
              <FieldValue 
                fields={fields} field="office_phone"
                setValues={props.setValues} 
                adminVisible={props.adminVisible} 
                reloadValuesCallback={props.reloadValuesCallback} />
            </div>
            <div>
              <FieldValue 
                fields={fields} field="manager_email"
                setValues={props.setValues} 
                adminVisible={props.adminVisible} 
                reloadValuesCallback={props.reloadValuesCallback} 
                renderText={(xx) => <a href={"mailto:"+xx}>{xx}</a> } />
            </div>
        </div>
      </div>
      <div className={styles["Fuel-Root"]}>
        <div className={styles["Fuel-Left"]}>
          <i className="demo-icon icon-fuel" />
        </div>
        <div className={styles["Fuel-Middle"]}>
          <div>
            <FieldValue 
              fields={fields} field="fuel_text"
              setValues={props.setValues} 
              adminVisible={props.adminVisible} 
              reloadValuesCallback={props.reloadValuesCallback} />
          </div>
          <div>
            <FieldUpdated 
              fields={fields} 
              field="fuel_price" 
              renderText={(xx) => "Updated "+(new Date(xx*1000)).toISOString().split('T')[0]}  />
          </div>
        </div>
        <div className={styles["Fuel-Right"]}>
          <FieldValue 
            fields={fields} field="fuel_price" 
            setValues={props.setValues} 
            adminVisible={props.adminVisible} 
            reloadValuesCallback={props.reloadValuesCallback} />
        </div>
      </div>
    </div>
  );
}

export default Footer;
