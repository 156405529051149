import styles from './Timeline.module.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from './Banner'
import TimelineAlbum from './TimelineAlbum'

function Timeline(props) {
  const content = JSON.parse(props.content);
  const location = content["location"];
  const pageTitle = content["title"];
  const params = props.params;

  let [entries, setEntries] = useState([]);

  // This will expand to http://localhost:8080/history for local dev
  //                     /history for production
  const historyEndpoint = `${process.env.REACT_APP_PLUGIN_HOST}/${location}`; 

  let base = `${historyEndpoint}/`; 

  // fetch the history entries
  useEffect(() => {
    const historyEntryUrl = `${process.env.REACT_APP_PLUGIN_HOST}/${location}/index.json`
    console.log(`Fetching history entries from ${historyEntryUrl}`)
    //actually fetch the page
    fetch(historyEntryUrl)
    .then(res => res.text())
    .then(
      (result) => {
        setEntries(JSON.parse(result)["entries"]);
      },
      (error) => {
        console.error(error);
        console.error(`Could not fetch history entries from ${historyEntryUrl}. Verify the server is up.`);
      }
    );
  }, [location]);

  function geta(arr,ii,def){
    return (arr.length >= ii-1) ? arr[ii] : def;
  };

  // Top case corresponds to album view activates
  let paramParts = params.split("/");
  let display = { "mode" : geta(paramParts, 0, ""), "expanded" : [] };
  display["expanded"] = paramParts.slice(1); //all elements after mode
  if(display["mode"] === "album"){
    // second to last entry in path string is folder, last is file
    display["showingEntry"] = geta(paramParts, paramParts.length-2, "");
    display["showingFile"] = geta(paramParts, paramParts.length-1, "");
    // last item is the file name so dont include that in expanded
    display["expanded"] = paramParts.slice(1,-1);
    // find the entry corresponding to the showingEntry string
    entries.forEach(ee => {
      if(ee.path === display["showingEntry"]){
        display["showingEntryObj"] = ee;
      }
    });
    if(display["showingEntryObj"] == null){
      console.log(`Attempted to load entry ${display["showingEntry"]} but none existed with that name.`);
    }
  }

  // remove empty "//" from the expanded url
  display["expanded"] = display["expanded"].filter(function (el) {
    return el !== "";
  });

  // make a list of years to display
  let years = {};

  // walk through the entries and put them in buckets, by year
  entries.forEach(ee => {
    // skip anything with hideme in the title
    if(ee["title"].toLowerCase().indexOf("hideme") !== -1){
      return ;
    }

    // we split the string on "-" and take the first element as the year
    let yearParts = ee.date.split("-");
    ee["yearStr"] = yearParts[0];
    ee["monthStr"]  = yearParts.length >= 2 ? yearParts[1] : "";
    ee["dateStr"] = yearParts.length >= 3 ? yearParts[2] : "";

    if(! (ee["yearStr"] in years) ){
      years[ee["yearStr"]] = {};
    }

    let positionInYear = `${ee["monthStr"]}-${ee["dateStr"]}`;
    if(positionInYear === "-"){
      positionInYear = "";
    }
    years[ee["yearStr"]][positionInYear] = ee;
  });

  return (
    <div className={styles["Root"]}>
      <Banner src="history_banner2.jpg" height="200px" />
      <h1 className={styles["History-Title"]}>{pageTitle}</h1>
      {
        Object.keys(years).sort().reverse().map((year, ii) => {
          let yearObj = years[year];
          return <TimelineYear key={"TimelineYear"+ii} expanded={display["expanded"]} base={base} year={year} yearObj={yearObj} />;
        })
      }
      {
        (display["mode"] === "album" && <TimelineAlbum expanded={display["expanded"]} base={base} entryObj={display["showingEntryObj"]} photoString={display["showingFile"]} />)
      }
    </div>
  );

}

function TimelineYear(props){
  let year = props.year;
  let yearObj = props.yearObj;
  let expanded = props.expanded;


  return (
    <div className={styles["Year-Root"]}>
      <div className={styles["Year-Label"]}>{year}</div>
      <div className={styles["Year-Main"]}>
      {
        Object.keys(yearObj).sort().reverse().map((positionInYear, ii) => {
          let entryObj = yearObj[positionInYear];
          if(expanded.includes(entryObj.path)){
            return <TimelineExpandedEntry key={"TimelineEntry"+ii} expanded={props.expanded} base={props.base} entryObj={entryObj} />;
          }
          else{
            return <TimelineEntry key={"TimelineEntry"+ii} expanded={props.expanded} base={props.base} entryObj={entryObj} />;
          }
        })
      }
      </div>
    </div>
  );
}


function TimelineEntry(props){
  let entryObj = props.entryObj;

  let dateStr = makeDateStr(entryObj);
  if(dateStr) dateStr = " - " + dateStr;

  let thumbnailSrc = `${props.base}/${entryObj.path}/${entryObj.thumbnail}`;

  // make the href address for expanding
  // Appends form is history/e/{ee0.path}/{ee1.path}/list/goes/here
  let newExpanded = props.expanded.slice(); // slice makes a copy
  const index = newExpanded.indexOf(entryObj.path);
  if (index > -1) {
    newExpanded.splice(index, 1);
  }
  newExpanded.push(entryObj.path);
  let entryHref = "e/" + newExpanded.join("/");
  //console.log(`entryHref=${entryHref}`);
  
  return (
      <div className={styles["Entry-Root"]} >
        <Link to={entryHref}>
          <div className={styles["Entry-Preview-Card"]}>
            <div className={styles["Entry-Thumbnail"]} style={{ backgroundImage: `url(${thumbnailSrc})` }}>
              <div className={styles["Entry-Thumbnail-Image-Count"]} >
                {entryObj.photos.length}
              </div>
            </div>
            <div className={styles["Entry-Text"]}>
              <div className={styles["Entry-Title"]} >{entryObj.title}{dateStr}</div>
              <div className={styles["Entry-Description"]} >{entryObj.description}</div>
            </div>
          </div>
        </Link>
      </div>
  );
}

function TimelineExpandedEntry(props){
  let entryObj = props.entryObj;

  let dateStr = makeDateStr(entryObj);
  if(dateStr) dateStr = " - " + dateStr;

  let thumbnailSrc = `${props.base}/${entryObj.path}/${entryObj.thumbnail}`;

  // make the href address for collapsing
  // Appends form is history/e/{ee0.path}/{ee1.path}/list/goes/here
  let newExpanded = props.expanded.slice(); // slice makes a copy
  const index = newExpanded.indexOf(entryObj.path);
  if (index > -1) {
    newExpanded.splice(index, 1);
  }
  let entryHref = "e/" + newExpanded.join("/");
  //  for opening album
  //  history/album/{ee0.path}/{ee1.path/THIS_PATH_AT_END
  newExpanded.push(entryObj.path);
  let albumHrefPrefix = "album/" + newExpanded.join("/");
  //console.log(`entryHref=${entryHref} albumHrefPrefix=${albumHrefPrefix}`);


  // This href brings up a black slideshow view

  return (
      <div className={styles["Entry-Root"]} >
        <div className={styles["Entry-Expanded"]}>
          <Link to={entryHref}>
            <div className={styles["Entry-Preview-Card-Expanded"]}>
              <div className={styles["Entry-Thumbnail"]} style={{ backgroundImage: `url(${thumbnailSrc})` }}>
                <div className={styles["Entry-Thumbnail-Image-Count"]} >
                  {entryObj.photos.length}
                </div>
              </div>
              <div className={styles["Entry-Text"]}>
                <div className={styles["Entry-Title"]} >{entryObj.title}{dateStr}</div>
                <div className={styles["Entry-Description"]} >{entryObj.description}</div>
              </div>
            </div>
          </Link>
          <div className={styles["Entry-Expanded-Cards"]}>
          {
            entryObj["photos"].map((mediaObj, ii) => {
              if(mediaObj["type"] === "video"){
                let videoSrc = `${props.base}/${entryObj.path}/${mediaObj["src"]}`;
                return (
                  <div key={"Video"+ii} className={styles["Video-Card"]}>
                    <div className={styles["Video-Thumbnail"]}>
                      <video controls autoPlay={false} className={styles["Video-Video"]}>
                        <source src={videoSrc} type="video/mp4" />
                        <p>Your browser doesn't support HTML video. Here is a <a href={videoSrc}>link to the video</a> instead.</p>
                      </video>
                    </div>
                    <div className={styles["Video-Caption"]}>
                      {mediaObj["caption"]}
                    </div>
                  </div>
                )
              }
              else if(mediaObj["type"] === "image"){
                let photoSrc = `${props.base}/${entryObj.path}/thumbnails/${mediaObj["src"]}`;
                let entry_href_route = `${albumHrefPrefix}/${mediaObj["src"]}`;
                return (
                  <Link key={"Photo"+ii} to={entry_href_route}>
                    <div className={styles["Photo-Card"]}>
                      <div className={styles["Photo-Thumbnail"]} style={{ backgroundImage: `url(${photoSrc})` }}></div>
                      <div className={styles["Photo-Caption"]}>
                        {mediaObj["caption"]}
                      </div>
                    </div>
                  </Link>
                )  
              }
              else{
                const err = "Unrecognized media type=\""+mediaObj["type"]+"\"";
                console.error(err);
                return (<div>{err}</div>);
              }

            })
          }
          </div>
        </div>
      </div>
  );
}


// This creates a string representation of a photo's month and year, if known
function makeDateStr(entryObj){
  let months = ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sep","Oct","Nov","Dec"];

  let dateStr = "";
  if(entryObj.monthStr){
    dateStr += months[parseInt(entryObj.monthStr)-1];
    if(entryObj.dateStr){
      dateStr += " " + entryObj.dateStr;
    }
  }
  return dateStr;
}

export default Timeline;
