import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styles from './FileUploader.module.css';
import React, { useState, useRef, useCallback } from 'react';

function FileUploader(props){
  const [progressList, setProgressList] = useState([]);

  const reloadListCallback = props.reloadListCallback;

  const uploadRef = useRef();

  const uploadFileCallback = useCallback(() => {
    let progressList = [];
    for(let ii=0; ii < uploadRef.current.files.length; ii++) {
      const file = uploadRef.current.files[ii];
      progressList.push({
        "name" : file.name,
        "status" : "queued",
        "progress" : 0,
        "file" : file
      });
    }
    setProgressList(() => progressList);

    progressList.forEach((el, ii) => {
      const url = process.env.REACT_APP_API_HOST+"/file/"+el.file.name;
      var formData = new FormData();
      formData.append("file", el.file);
      var xhr = new XMLHttpRequest();

      xhr.upload.addEventListener("progress", (ee) => {
        setProgressList((xx) => {
            let yy = [...xx];
            yy[ii].status = "uploading";
            yy[ii].progress = Math.round((ee.loaded / ee.total) * 100);
            return yy;
          }
        )
      }, false);

      xhr.addEventListener("load", () => {
        setProgressList((xx) => {
            let yy = [...xx];
            yy[ii].status = "finished";
            return yy;
          }
        )
        reloadListCallback();
      }, false);

      xhr.addEventListener("error", () => {
        setProgressList((xx) => {
            let yy = [...xx];
            yy[ii].status = "error";
            return yy;
          }
        )
      }, false);

      xhr.addEventListener("abort", () => {
        setProgressList((xx) => {
            let yy = [...xx];
            yy[ii].status = "aborted";
            return yy;
          }
        )
      }, false);

      xhr.open("POST", url);
      xhr.withCredentials = true;
      xhr.send(formData);
    });

  }, [reloadListCallback]);

  return (
    <div className={styles["Root"]}>
      <div className={styles["UglyFile"]}>
        <input type='file' className={styles["FileElement"]} ref={uploadRef} onChange={uploadFileCallback} multiple />
      </div>


      <div className={styles["FileUploadDiv"]}>
        <div className={styles["Expand"]} onClick={() => {uploadRef.current.click()}}>Upload</div>
        {
          progressList.map((file, ii) => {
            const progress = file["progress"];
            const name = file["name"];
            const status = file["status"];

            return (<div key={"file"+ii} className={styles["ProgressFile"]}>
              <div className={styles["ProgressBar"]}>
                {status === "queued" && <div><i className={styles["ProgressIcon"]+" demo-icon icon-check-empty"} /></div>}
                {status === "uploading" && <CircularProgressWithLabel value={progress} />}
                {status === "finished" && <i className={styles["ProgressIcon"]+" demo-icon icon-ok"} />}
                {status === "aborted" && <div>A</div>}
                {status === "error" && <div>E</div>}
              </div>
              <div className={styles["ProgressFilename"]}>{name}</div>
            </div>)
          })
        }
      </div>

    </div>
  );
}


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default FileUploader;
