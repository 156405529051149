import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import styles from './LinkbarTable.module.css';
import React, { useState, useRef, useCallback } from 'react';
import Popover from '@mui/material/Popover';

function LinkbarTable(props){
  const [addEnabled, setAddEnabled] = useState(false);
  const [popAnchor, setPopAnchor] = useState(null);
  const [popText, setPopText] = useState("");

  const newLinkRef = useRef(null);

  const updateAddEnabled = useCallback((ee,vv) => {
    setAddEnabled(ee.target.value !== "---select-a-route---");
  }, [setAddEnabled]);

  const routesToAdd = Object.keys(props.values.routes).filter(
    (el) => props.values.linkbar.findIndex( (xx) => xx["route"] === el ) === -1
  );

  return (
    <div className={styles["LinkRoot"]}>
      <div className={styles["LinkTable"]}>
        <table className={styles["AdminTableTop"]}>
        <tbody>
          <tr key="row-header">
            <td className={styles["AdminTableHeader"]}>
              Route
              <i className="demo-icon icon-help-circled" onClick={(ee) => {setPopText("This route must exactly match the route specified on the Page table."); setPopAnchor(ee.currentTarget);}} />
            </td>
          </tr>
          {
            props.values.linkbar.map((link,ii) => (
              <tr key={"linkbar"+ii}>
                <td className={styles["AdminTableCell"]}>
                  { link["route"] === "" && <TextField id="user-text" variant="outlined" size="small" disabled defaultValue="Default" />}
                  { link["route"] !== "" &&
                    <TextField 
                      id="user-text" 
                      label=""
                      onChange={
                        (ee) => {
                          props.setValues(
                            (xx) => {
                              let yy = {...xx};
                              yy["linkbar"][ii]["route"] = ee.target.value;
                              return yy;
                            }
                          )
                        }
                      } 
                      variant="outlined" 
                      size="small" 
                      value={link["route"]} />
                    }
                </td>
                <td className={styles["AdminTableCell"]}>
                  <Button 
                    id="user-text"
                    label=""
                    className={styles["MuiShrinkableButton"]}
                    disabled={link["route"] === ""}
                    onClick={
                      (ee) => {
                        props.setValues(
                          (xx) => {
                            let yy = {...xx};
                            yy["linkbar"].splice(ii, 1);
                            return yy;
                          }
                        )
                      }
                    } 
                    variant="outlined">Del</Button>
                </td>
                <td className={styles["AdminTableCell"]}>
                  <Button 
                    id="user-text"
                    label=""
                    className={styles["MuiShrinkableButton"]}
                    onClick={
                      (ee) => {
                        props.setValues(
                          (xx) => {
                            let yy = {...xx};
                            let val = yy["linkbar"][ii-1];
                            yy["linkbar"][ii-1] = yy["linkbar"][ii];
                            yy["linkbar"][ii] = val;
                            return yy;
                          }
                        )
                      }
                    } 
                    disabled={ii === 0}
                    variant="outlined">Up</Button>
                </td>
                <td className={styles["AdminTableCell"]}>
                  <Button 
                    id="user-text"
                    label=""
                    className={styles["MuiShrinkableButton"]}
                    onClick={
                      (ee) => {
                        props.setValues(
                          (xx) => {
                            let yy = {...xx};
                            let val = yy["linkbar"][ii+1];
                            yy["linkbar"][ii+1] = yy["linkbar"][ii];
                            yy["linkbar"][ii] = val;
                            return yy;
                          }
                        )
                      }
                    } 
                    disabled={ii === props.values["linkbar"].length-1}
                    variant="outlined">Down</Button>
                </td>
              </tr>
            ))
          }
        </tbody>
        </table>
      </div>

      <div className={styles["AddLinkRoot"]}>
        <div className={styles[""]}>
          <TextField 
            id="link-route" 
            select
            inputRef={newLinkRef}
            onChange={updateAddEnabled}
            label=""
            variant="outlined"
            defaultValue="---select-a-route---"
            size="small">
            <MenuItem key="---select-a-route---" value="---select-a-route---">Select a route</MenuItem>
            {
              routesToAdd.sort().map( (route, ii) => (
                <MenuItem key={route} value={route}>{route === "" ? "[Default]" : route}</MenuItem>
              ))
            }
          </TextField>
        </div>
        <div className={styles[""]}>
          <Button 
            id="add-token"
            label=""
            onClick={!addEnabled ? ()=>{} :
              (ee) => {
                props.setValues(
                  (xx) => {
                    let yy = {...xx};
                    yy["linkbar"].push(
                      {
                        "route" : newLinkRef.current.value
                      }
                    );
                    setAddEnabled(false);
                    return yy;
                  }
                )
              }
            } 
            variant={addEnabled ? "contained" : "outlined"} disabled={!addEnabled}>Add</Button>
        </div>
      </div>
      <Popover id="popover-name-help" open={popAnchor !== null} anchorEl={popAnchor}
        onClose={()=>setPopAnchor(null)} anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}>
        <div className={styles["Popup"]}>
          {popText}
        </div>
      </Popover>
    </div>
  );
}

export default LinkbarTable;
