import styles from './MarkdownPage.module.css';
import React from "react";
import MarkdownView from 'react-showdown';
import Banner from './Banner';
import PlaceImage from './PlaceImage';
import PlaceVideo from './PlaceVideo';
import { Link } from 'react-router-dom';

class MarkdownPage extends React.Component {
  render() {
    //const linkbar = this.props.linkbar;
    //const pageChangeFunction = this.props.pageChangeFunction;
    //const page = this.props.page;

    /*
    const markdown = `
    # Welcome to React Showdown :+1:
    
    To get started, edit the markdown in \`example/src/App.tsx\`.
    
    | Column 1 | Column 2 |
    |----------|----------|
    | A1       | B1       |
    | A2       | B2       |
    `;
    */

    let markdown = this.props.content;

    return (
      <div className={styles["Root"]}>
        <div className={styles["Embedded-Content"]}>
          <MarkdownView markdown={markdown} components={{ Link, Banner, PlaceImage, PlaceVideo }} options={{ tables: true }} />
        </div>
      </div>
    );
  }

}

export default MarkdownPage;
