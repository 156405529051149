import Slideshow from './Slideshow'
import MarkdownPage from './MarkdownPage'
import HtmlPage from './HtmlPage'
import Timeline from './Timeline'
import EditPage from './EditPage'
import styles from './Page.module.css';
import Button from '@mui/material/Button';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';


function Page(props) {
  const [type, setType] = useState("loading");
  const [fetchedPage, setFetchedPage] = useState("");
  const [content, setContent] = useState("");
  const [lastSavedContent, setLastSavedContent] = useState("");
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [editMode, setEditMode] = useState(false);

  let extractedParams = useParams();
  let route = "route" in extractedParams ? extractedParams["route"] : "";
  let params = "*" in extractedParams ? extractedParams["*"] : "";

  const setPageTitlePrefix = props.setPageTitlePrefix;
  const adminVisible = props.adminVisible;

  const reloadPageContent = useCallback((page) => {
    //get page name
    console.log("Fetching route="+route+" page="+page);

    // determine the type of content
    let contentType = null;
    if(page.endsWith(".md")){
      contentType = "markdown";
    }
    else if(page.endsWith(".slideshow.json")){
      contentType = "slideshow";
    }
    else if(page.endsWith(".timeline.json")){
      contentType = "timeline";
    }
    else if(page.endsWith(".html")){
      contentType = "html";
    }
    else{
      let errorMessage = `Unrecognized content type for page "${page}"`;
      console.error(errorMessage);
      setType("error");
      setContent(errorMessage);
      setLastSavedContent(errorMessage);
      setFetchedPage(page);
      return ;
    }

    //actually fetch the page
    let pageURL = process.env.REACT_APP_API_HOST+"/page/"+page;
    console.log(`Fetching page from ${pageURL}`);
    fetch(pageURL)
    .then(res => res.json())
    .then(
      (result) => {
        setType(contentType);
        setContent(result["content"]);
        setLastSavedContent(result["content"]);
        setFetchedPage(page);
      },
      (error) => {
        setType("error");
        setContent(error);
        setLastSavedContent(error);
        setFetchedPage(page);  
        console.error(error);
      }
    );

  }, [route]);

  const saveCallback = useCallback(() => {
    const url = process.env.REACT_APP_API_HOST+"/page/"+fetchedPage;
    console.log(`Saving page to ${url}`);
    const body = {
      "content" : content
    }
    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then(res => res.json())
    .then(
      (result) => {
        reloadPageContent(fetchedPage);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [content, fetchedPage, reloadPageContent]);

  const revertCallback = useCallback(() => {
    setContent(lastSavedContent)
  }, [setContent, lastSavedContent]);

  useEffect(() => {
    const values = props.values;

    // Ignore calls to this function before values have been populated
    if(!("routes" in values)){
      return ;
    }

    // This loop replaces routeString with the corresponding page if an entry is found in the linkbar.
    // Otherwise, page is passed through unmodified to support static content.
    let page = null;
    if(values.routes && route in values.routes){
      page = values.routes[route].page;
      setPageTitlePrefix(route ? values.routes[route].text : "");
    }
    else{
      console.log(values.fields);
      let errorMessage = `Invalid route "${route}"`;
      if("404" in values.fields){
        page = values.fields["404"].value;
      }
      else if(values.fields){
        setType("error");
        setContent(errorMessage);  
        console.warn(`User loaded invaild route "${route}" and no 404 field was set in values to handle the error.`)
      }
    }

    // if we already fetched the page, don't fetch it again
    if(page === null || fetchedPage === page){
      return ;
    }

    // if no values have been loaded yet, blank the screen and don't fetch anything
    if(values.linkbar.length === 0){
      return ;
    }

    reloadPageContent(page);
  }, [props.values, setPageTitlePrefix, route, params, fetchedPage, reloadPageContent]);

  const pageChangeFunction = props.pageChangeFunction;

  let body = null;

  if(type === "loading"){
    body = (
      <div className={styles["Loading"]}>
        Loading
      </div>
    );
  }
  else if(editMode){
    body = (
      <EditPage content={content} setContent={setContent} setSaveEnabled={setSaveEnabled} lastSavedContent={lastSavedContent} fetchedPage={fetchedPage} reloadPageContent={reloadPageContent}/>
    );
  }
  else if(type === "slideshow"){
    body = (
      <Slideshow pageChangeFunction={pageChangeFunction} params={params} content={content}/>
    );
  }
  else if(type === "timeline"){
    body = (
      <Timeline pageChangeFunction={pageChangeFunction} params={params} content={content}/>
    );
  }
  else if(type === "markdown"){
    body = (
      <MarkdownPage pageChangeFunction={pageChangeFunction} params={params} content={content}/>
    );
  }
  else if(type === "html"){
    body = (
      <HtmlPage pageChangeFunction={pageChangeFunction} params={params} content={content}/>
    );
  }
  else if(type === "error"){
    body = (
      <div className={styles["Error"]}>Error: {content}</div>
    );
  }
  else if(type === "loading"){
    body = (
      <div className={styles["Loading"]}></div>
    );
  }
  return (
    <div className={styles["Root"]}>
      {body}
      {adminVisible && <div className={styles["SaveButtonRoot"]}>
        <Button 
            id="revert-page"
            label=""
            onClick={saveEnabled ? revertCallback : ()=>{}}
            variant={saveEnabled ? "contained" : "outlined"} disabled={!saveEnabled}>Revert</Button>
        <Button 
            id="save-page"
            label=""
            onClick={saveEnabled ? saveCallback : ()=>{}}
            variant={saveEnabled ? "contained" : "outlined"} disabled={!saveEnabled}>Save</Button>
        {!editMode && <Button 
            id="edit-page"
            label=""
            onClick={()=>{ setEditMode(true); }}
            variant={"outlined"}>Edit</Button>}
        {editMode && <Button 
            id="preview-page"
            label=""
            onClick={()=>{ setEditMode(false); }}
            variant={"outlined"}>Preview</Button>}

      </div>}
    </div>
  );

}

export default Page;
