import styles from './TimelineAlbum.module.css';
//import React, { useEffect, useState, useEffect } from 'react';
import React, {  useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function TimelineAlbum(props) {
  let base = props.base;
  let entryObj = props.entryObj;
  let photoString = props.photoString;
  let path = entryObj["path"];

  let extractedParams = useParams();
  let route = "route" in extractedParams ? extractedParams["route"] : "";

  // we prepend album to the url route=history/album
  let albumRoute = extractedParams["*"].split("/")[0]; //this is just "album"

  let expandedPathStr = props.expanded.join("/") // ignores last
  
  const itemsRef = useRef(Array(entryObj["photos"].length));
  const videoElementRef = useRef(Array(entryObj["photos"].length));
  const bottomTextRef = useRef(null);
  const rootRef = useRef(null);

  let closeHref = `/${route}/e/${expandedPathStr}/`;

  // load the file
  let showingPhotoIndex = 0;
  let showingPhotoObj = null;
  if(photoString !== ""){
    entryObj.photos.forEach((ee, ii) => {
      if(ee.src === photoString){
        showingPhotoObj = ee;
        showingPhotoIndex = ii;
      }
    });
    if(showingPhotoObj == null){
      console.log(`Attempted to load photo ${photoString} but none existed with that name.`);
    }
  }

  const handleWindowResize = (event) => {
    // console.log("Window resize");
    let el = videoElementRef.current[showingPhotoIndex];
    if(el){
      let bottomHeight = bottomTextRef.current.getBoundingClientRect().height;
      let rootRect = rootRef.current.getBoundingClientRect();
      // console.log(`bottomHeight=${bottomHeight} rootHeight=${rootHeight}`);
      el.style.height = (rootRect.height - bottomHeight) + "px";
      el.style.width = rootRect.width + "px";
    }
  };

  // register the resize function
  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    // cleanup this component
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  // show the first image
  useEffect(() => {
    itemsRef.current.forEach(ee => {
      ee.style.display = "none";
    });
    itemsRef.current[showingPhotoIndex].style.display = "";

    // copy of handleWindowResize
    let el = videoElementRef.current[showingPhotoIndex];
    if(el){
      let bottomHeight = bottomTextRef.current.getBoundingClientRect().height;
      let rootRect = rootRef.current.getBoundingClientRect();
      // console.log(`bottomHeight=${bottomHeight} rootHeight=${rootHeight}`);
      el.style.height = (rootRect.height - bottomHeight) + "px";
      el.style.width = rootRect.width + "px";
    }    

  }, [showingPhotoIndex]);

  return (    
    <div ref={el => rootRef.current = el} className={styles["Root"]}>
      {
        entryObj["photos"].map((mediaObj, ii) => {
          let src = `${base}/${path}/${mediaObj["src"]}`;

          let mediaJSX = null;
          if(mediaObj["type"] === "video"){
            mediaJSX = (<video controls ref={el => videoElementRef.current[ii] = el} autoPlay={false} className={styles["Media-Video"]}>
                <source src={src} type="video/mp4" />
                <p>Your browser doesn't support HTML video. Here is a <a href={src}>link to the video</a> instead.</p>
              </video>);
          }
          else if(mediaObj["type"] === "image"){
              //mediaJSX = (<img src={src} alt="" className={styles["Media-Image"]}

              mediaJSX = (<div className={styles["Media-Image-Div"]}
                            style={{backgroundImage:`url(${src})`}}></div>);
            }
          else{
            console.error("Unrecognized media type=\""+mediaObj["type"]+"\"");
          }

          // hide arrow
          let hidePrevArrow = (ii === 0) ? " "+styles["Hidden"] : "";
          let hrefPrev = (ii === 0) ? "" : `/${route}/${albumRoute}/${expandedPathStr}/` + entryObj["photos"][ii-1].src;
          let hideNextArrow = (ii === entryObj["photos"].length-1) ? " "+styles["Hidden"] : "";
          let hrefNext = (ii === entryObj["photos"].length-1) ? "" : `/${route}/${albumRoute}/${expandedPathStr}/` + entryObj["photos"][ii+1].src;

          return (
            <div key={"item"+ii} ref={el => itemsRef.current[ii] = el} className={styles["Item-Root"]}>
              <div className={styles["Media"]}>{mediaJSX}</div>
              <div className={styles["Bottom"]}>
                <div className={styles["Bottom-Background"]}></div>
                <div ref={el => bottomTextRef.current = el} className={styles["Bottom-Text"]}>
                  <div className={styles["Left"]+hidePrevArrow}>
                    <Link to={hrefPrev}><i className={`demo-icon icon-left-big`} /></Link>
                  </div>
                  <div className={styles["Caption"]}>{mediaObj["caption"]}</div>
                  <div className={styles["Right"]+hideNextArrow}>
                    <Link to={hrefNext}><i className={`demo-icon icon-right-big`} /></Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      }

      <div className={styles["Close"]}>
        <div className={styles["Close-Background"]}></div>
        <div className={styles["Close-Text"]}>
        <Link to={closeHref}><i className="demo-icon icon-cancel" /></Link>
        </div>
      </div>

    </div>

);

}

export default TimelineAlbum;
