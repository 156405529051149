import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import styles from './FileObject.module.css';
import React, { useState, useRef, useCallback } from 'react';

function FileObject(props){
  const [renameDisabled, setRenameDisabled] = useState(true);
  const nameTextfieldRef = useRef(null);

  const fileName = props.fileObj["name"];
  const fileMime = props.fileObj["mimetype"];
  //const fileSize = props.fileObj["size"];
  //const fileTime = props.fileObj["mtime"];
  const fileTime = props.fileObj["ctime"];
  const fileUrl = process.env.REACT_APP_FILE_PREFIX + "/" + fileName;
  const reloadListCallback = props.reloadListCallback;
  const setError = props.setError;

  const renameCallback = useCallback(() => {
    const url = process.env.REACT_APP_API_HOST+"/file/rename/"+fileName;
    console.log(`Renaming file ${url}`);
    const body = {
      "name" : nameTextfieldRef.current.value
    }
    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then(res => res.json())
    .then(
      (result) => {
        if(result["result"] === "error"){
          setError(result["message"]);
        }
        reloadListCallback();
      },
      (error) => {
        console.error(error);
        setError(error);
      }
    );
  }, [fileName, reloadListCallback, nameTextfieldRef, setError]);

  const deleteCallback = useCallback(() => {
    const url = process.env.REACT_APP_API_HOST+"/file/"+fileName;
    console.log(`Deleting file ${url}`);
    fetch(url, {
      method: 'DELETE',
      credentials: 'include',
    })
    .then(res => res.json())
    .then(
      (result) => {
        if(result["result"] === "error"){
          setError(result["message"]);
        }
        reloadListCallback();
      },
      (error) => {
        console.error(error);
        setError(error);
      }
    );
  }, [fileName, reloadListCallback, setError]);

  const nameUpdated = useCallback(() => {
    setRenameDisabled(
      nameTextfieldRef.current.value === fileName || 
      nameTextfieldRef.current.value === ""
    );
  }, [setRenameDisabled, fileName]);

  return (
    <div className={styles["FileObjectRoot"]}>

      { fileMime.startsWith("image") && 
        <div className={styles["ThumbnailImage"]} style={{
              backgroundImage:`url(${fileUrl})`,
              backgroundPosition:"center",
              backgroundRepeat:"no-repeat" }}>
              <a href={fileUrl} className={styles["ThumbnailImageHref"]} target="_blank" rel="noreferrer">&nbsp;</a>
        </div>
      }
      { !fileMime.startsWith("image") && 
        <div className={styles["ThumbnailImage"]} >
              <a href={fileUrl} className={styles["ThumbnailImageHref"]} target="_blank" rel="noreferrer">&nbsp;</a>
        </div>
      }


      <div className={styles["ControlDiv"]}>
        <div className={styles["ControlDivName"]}>
          <TextField 
            id="field-value" 
            inputRef={nameTextfieldRef}
            label=""
            variant="outlined" 
            size="small" 
            onChange={nameUpdated}
            defaultValue={fileName} 
            sx={{
                  width: "100%",
                  maxWidth: "400px",
                  minWidth: "40px"
                }}
          />
        </div>
        <div className={styles["ControlDiv2ndRow"]}>
          <div className={styles["ControlDivButtons"]}>
            <div>
              <Button 
                id="del-field"
                label=""
                onClick={deleteCallback}
                variant="outlined">Del</Button>
            </div>
            <div>
            <Button 
                id="rename-field"
                label=""
                onClick={renameCallback}
                disabled={renameDisabled ? true : false}
                variant="outlined">Rename</Button>
            </div>
          </div>
          <div className={styles["ControlDivDate"]}>
            {new Date(fileTime*1000).toISOString().split('T')[0]}
          </div>

        </div>
      </div>
    </div>
  );
}


export default FileObject;
