import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import styles from './Admin.module.css';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import LinkbarTable from './LinkbarTable';
import PageTable from './PageTable';
import FileTable from './FileTable';
import TokenTable from './TokenTable';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'react-router-dom';
import _ from "lodash"
import { useParams } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

function Admin(props) {
  let extractedParams = useParams();
  let section = "section" in extractedParams ? extractedParams["section"] : "";
  let presentedToken = "token" in extractedParams ? extractedParams["token"] : "";
  //let params = "*" in extractedParams ? extractedParams["*"] : "";

  const setPageTitlePrefix = props.setPageTitlePrefix;
  useEffect(() => setPageTitlePrefix("Admin"), [setPageTitlePrefix]);

  const showLoginPage = presentedToken !== "" || !props.adminAvailable; 

  return (
    <div className={styles["Root"]}>
      { showLoginPage && <AdminLogin presentedToken={presentedToken} {...props} /> }
      { !showLoginPage && <AdminTop section={section} {...props} /> }
    </div>
  );

}

function AdminTop(props) {
  const [saveEnabled, setSaveEnabled] = useState(false);

  const lastSavedValues = props.lastSavedValues;
  const values = props.values;
  const setValues = props.setValues;
  const admin = props.values["auth"]["admin"]
  const section = props.section;

  useEffect(() => {
    setSaveEnabled(!_.isEqual(values, lastSavedValues));
  }, [values, lastSavedValues, saveEnabled]);

  const reloadValuesCallback = props.reloadValuesCallback;
  const userName = "auth" in props.values ? props.values["auth"]["name"] : "";

  const logoutCallback = useCallback(() => {
    const url = process.env.REACT_APP_API_HOST+"/logout";
    fetch(url, {
      method: 'GET',
      credentials: 'include'
    })
    .then(res => res.json())
    .then(
      (result) => {
        if(result["result"] === "ok"){
          reloadValuesCallback();
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }, [reloadValuesCallback]);

  const revertCallback = useCallback(() => {
    setValues(
      (_) => {
        return structuredClone(lastSavedValues);
      }
    )
  }, [setValues, lastSavedValues]);

  const saveCallback = useCallback(() => {
    const url = process.env.REACT_APP_API_HOST+"/values";
    const body = {
      "values" : values
    }
    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then(res => res.json())
    .then(
      (result) => {
        reloadValuesCallback();
      },
      (error) => {
        console.error(error);
      }
    );
  }, [values, reloadValuesCallback]);


  // TODO:
  // The section list winds up getting hard-coded a lot. The code below here would better be rewritten
  // in a way where all the info is contained in the section array, and it gets expanded using sections.map
  // into tabs and divs

  const sections = [
    {"section" : "pages"},
    {"section" : "linkbar"},
    {"section" : "files"},
    {"section" : "tokens"}
  ];

  const selectedTab = Math.max(0, sections.findIndex( (el) => (el["section"] === section) ));

  return (
    <div className={styles["AdminRoot"]}>
      <div className={styles["AdminTop"]}>
        
        <div className={styles["AdminTabs"]}>
          <Tabs className={styles["AdminMuiTabs"]} value={selectedTab} aria-label="Admin tabs">
            <Tab label="Pages" className={styles["AdminMuiTab"]} component={Link} to="/admin/pages" id='admin-tab-0' aria-controls='simple-tabpanel-0' />
            <Tab label="Linkbar" className={styles["AdminMuiTab"]} component={Link} to="/admin/linkbar" id='admin-tab-1' aria-controls='simple-tabpanel-1' />
            <Tab label="Files" className={styles["AdminMuiTab"]} component={Link} to="/admin/files" id='admin-tab-2' aria-controls='simple-tabpanel-2' />
            {admin && <Tab label="Tokens" className={styles["AdminMuiTab"]} component={Link} to="/admin/tokens" id='admin-tab-3' aria-controls='simple-tabpanel-3' /> }
          </Tabs>
        </div>

        <div className={styles["AdminLogout"]}>
          <Button variant={saveEnabled ? "contained" : "text"} disabled={!saveEnabled} className={styles["MuiShrinkableButton"]} onClick={saveCallback}>Save</Button>
          <Button variant={saveEnabled ? "contained" : "text"} disabled={!saveEnabled} className={styles["MuiShrinkableButton"]} onClick={revertCallback}>Revert</Button>
          <Button variant="text" onClick={logoutCallback}>Logout {userName}</Button>
        </div>

      </div>
      <div className={styles["AdminMain"]}>

        <div role="tabpanel" hidden={section !== "pages" && section !== ""} id='simple-tabpanel-0' aria-labelledby='simple-tab-0'>
          <div className={styles["AdminContentTop"]}>
            <PageTable {...props} />
          </div>
        </div>

        <div role="tabpanel" hidden={section !== "linkbar"} id='simple-tabpanel-1' aria-labelledby='simple-tab-1'>
          <div className={styles["AdminContentTop"]}>
            <LinkbarTable {...props} />
          </div>
        </div>

        <div role="tabpanel" hidden={section !== "files"} id='simple-tabpanel-2' aria-labelledby='simple-tab-2'>
          <div className={styles["AdminContentTop"]}>
            <FileTable {...props} />
          </div>
        </div>

        <div role="tabpanel" hidden={section !== "tokens"} id='simple-tabpanel-3' aria-labelledby='simple-tab-3'>
          <div className={styles["AdminContentTop"]}>
            <TokenTable {...props} />
          </div>
        </div>

      </div>
    </div>
  );
}

function AdminLogin(props) {
  const [error, setError] = useState("");
  const [hintToken, setHintToken] = useState("");
  const [loginDisabled, setLoginDisabled] = useState(true);
  const tokenRef = useRef(null);
  const reloadValuesCallback = props.reloadValuesCallback;
  const presentedToken = props.presentedToken;
  const navigate = useNavigate();

  const loginCallback = useCallback(() => {
    const url = process.env.REACT_APP_API_HOST+"/login";
    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({"token" : tokenRef.current.value})
    })
    .then(res => res.json())
    .then(
      (result) => {
        if(result["result"] === "ok"){
          reloadValuesCallback();
          navigate("/admin");
        }
        else if(result["result"] === "error"){
          setError(result["message"]);
        }
      },
      (error) => {
        console.error(error);
        setError(error);
      }
    );
  }, [reloadValuesCallback, navigate]);

  const tokenUpdated = useCallback(() => {
    setLoginDisabled(tokenRef.current.value === "");
    setHintToken(tokenRef.current.value);
  }, [setLoginDisabled]);

  useEffect(() => {
    tokenUpdated();
  }, [tokenUpdated]);

  const hintLink = window.location.origin + window.location.pathname + "#/admin/token/" + hintToken;

  return (
    <div className={styles["LoginTop"]}>
      <div className={styles["LoginBox"]}>
        <div className={styles["LoginToken"]}>
          <TextField id="token-text" 
            label="Token" 
            inputRef={tokenRef}
            variant="outlined" 
            type="text" 
            onChange={tokenUpdated}
            defaultValue={presentedToken}
            sx={{
              width: "100%",
              maxWidth: "400px",
              minWidth: "40px"
            }}
            />
        </div>
        <div className={styles["LoginButton"]}>
          <Button variant="text" disabled={loginDisabled} onClick={loginCallback}>Login</Button>
        </div>
        {hintToken !== "" && <div className={styles["MobileHint"]}>
          For quick mobile logins using this token, send yourself this link <a target="_blank" rel="noreferrer" href={hintLink}>{hintLink}</a>
        </div>}

        {error && <div className={styles["ErrorRoot"]} onClick={() => setError("")}>{error}</div>}
      </div>
    </div>
  );
}

export default Admin;
