import styles from './Linkbar.module.css';
import "./fontello/css/fontello.css"
import React from "react";
import { NavLink } from 'react-router-dom';


/*
TODO: rewrite this nasty mess to use dymanic media queries

matchMedia API is what's used here

https://medium.com/@amatewasu/how-to-dynamically-set-a-media-query-b6eb15e49e11
*/

class Linkbar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      linkOffPage : {}
    };

    this.linkbarHorizontalLinkRefs = [];
    this.linkbarPopupLinkRefs = [];
    this.linkbarPopupBackground = null;
    this.linkbarPopup = null;

    this.verticalMenuHasAtLeastOneItem = false;

    this.counter = 0;

    this.linkOverflowDiv = null;

    // This rather bizarre mess creates a callable named function (self.handleEventWrapper)
    // that is already bound. This is necessary because window.removeEventListener insists
    // on having a named function, but can't pass it any arguments (including the this). So
    // we have to create a wrapper function that binds this and invokes this.handleResize
    var wrapper = function( self ) {
      var handler = function(event) {
        self.handleResize();
      };
      return handler;
    };
    this.handleEventWrapper = wrapper(this);

  }

  componentDidMount() {
    window.removeEventListener('resize', this.handleEventWrapper);
    window.addEventListener('resize', this.handleEventWrapper);    
    this.computeLinkDimensions();
    this.handleResize(0);
    this.hideMorePopup();
  }

  componentDidUpdate(){
    this.computeLinkDimensions();
    this.handleResize(0);
    this.hideMorePopup();
  }

  /*
     Iterates over the linkbar and gets the bounding rectangle for each div. Sets up meta
     data so divs can be hidden and shown.
  */
  computeLinkDimensions(){

    // do nothing until we have links to show
    if(this.linkOverflowDiv === null){
      return ;
    }

    this.linkDimensions = [];

    let linksStartX = this.linkOverflowDiv.getBoundingClientRect().x;

    // iterate over all the linkbarHorizontalLinkRefs
    // Use linkbarHorizontal.children, not this.linkbarHorizontalLinkRefs, to avoid
    // a race condition with render. The former will always hold dom nodes, the latter
    // may go stale during render calls
    Array.from(this.linkbarHorizontalTop.children).forEach((ee, ii) => {
      //console.log(ee);
      let rect = ee.getBoundingClientRect();
      let rightBoundary = rect.x + rect.width;
      this.linkDimensions.push({
        "minwidth" : rightBoundary - linksStartX
      })
    });
  }

  handleResize(){

    // do nothing until we have links to show
    if(this.linkOverflowDiv === null){
      return ;
    }

    let ww = this.linkOverflowDiv.getBoundingClientRect().width;

    this.verticalMenuHasAtLeastOneItem = false;
    //console.log(`handleResize width=${ww}`);

    // this case happens when the admin console has changes the number of links,
    // but the re-render function hasn't finished updating the refs yet
    if(this.linkbarHorizontalLinkRefs.length !== this.linkDimensions.length){
      return ;
    }

    this.linkbarHorizontalLinkRefs.forEach((ee, ii) => {
      let delta = ww - this.linkDimensions[ii]["minwidth"];
      let showing = (delta >= -1);
      //console.log(`Link ii=${ii} window_width=${ww} minwidth=${this.linkDimensions[ii]["minwidth"]} delta=${delta} showing=${showing}`);
      //console.log("ww=" + ww + "  minwidth=" + this.linkDimensions[ii]["minwidth"] + "  delta=" + delta);
      
      //Note: 09/27/2023
      // this code implements the disabled only-hide-wrapping links behavior
      ee.style.visibility = showing ? "" : "hidden";

      //this.linkbarPopupLinkRefs[ii].style.display = showing ? "none" : "";
      this.verticalMenuHasAtLeastOneItem = this.verticalMenuHasAtLeastOneItem || !showing;
    });

    this.linkbarShowMore.classList.add(styles["Menu-Disabled"]);
    if(this.verticalMenuHasAtLeastOneItem){
      this.linkbarShowMore.classList.remove(styles["Menu-Disabled"]);
 
      // hide all links
      this.linkbarHorizontalLinkRefs.forEach((ee, ii) => {
        ee.style.visibility = "hidden";
      });
  
    }
    else{
      this.hideMorePopup();
    }
  }

  hideMorePopup(){
    // do nothing until we have links to show
    if(this.linkOverflowDiv === null){
      return ;
    }

    this.linkbarPopupBackground.style.display = "none";
    this.linkbarPopup.style.top = '-300px';
    this.linkbarHorizontalTop.style.visibility = "";
  }

  showMorePopup(){

    if(this.verticalMenuHasAtLeastOneItem){
      this.linkbarHorizontalTop.style.visibility = "hidden";
      this.linkbarPopupBackground.style.display = "";
      setTimeout(() => {this.linkbarPopup.style.top = '0px'}, 10  );
    }
  }

  render() {
    const values = this.props.values;

    if(!("linkbar" in values)){
      return <div>&nbsp;</div>;
    }

    // count the admin links, we'll decide if we want to use them or not later
    const adminLinks = "admin,admin-help".split(",");
    const admin = ("auth" in this.props.values && this.props.values["auth"]["authenticated"]);
    const totalLinkCount = values.linkbar.length + (admin ? adminLinks.length : 0);    

    // Note - it is very important to initialize the arrays to the correct number of elements
    // and then populate the fields in the callbacks.
    // 
    // This is because the callback gets called twice for each element on re-renders. The first
    // is null (unmount), the second is the element (mount). If we just append to the array 
    // we get double the number of elements in our array, where the first half are null
    this.linkbarHorizontalLinkRefs = Array(totalLinkCount);
    this.linkbarPopupLinkRefs = Array(totalLinkCount);

    /* Append all the links */
    let linkbarHorizontal = [];
    let linkbarPopup = [];
    values.linkbar.forEach((item, ii) => {
      if(!("route" in item)){
        console.error("Misconfigured linkbar item. Missing route.", item);
      }
      const route = item.route;
      let text = `[${route}]`;
      let extraClasses = "";
      if(route in values.routes){
        text = values.routes[item.route].text;
      } else {
        extraClasses = " "+styles["LinkError"];
        console.error(`Misconfigured linkbar item. Route ${route} not defined in routes.`, item);
      }

      linkbarHorizontal.push(
          <div key={"Hor-Linkbar-Link-Index-"+ii} ref={elem => this.linkbarHorizontalLinkRefs[ii] = elem} className={styles["Link"]}>
            <NavLink className={({ isActive }) => (isActive ? styles["Selected-Link"] : styles["Not-Selected-Link"])+extraClasses} to={route}>{text}</NavLink>
          </div>
        );
      linkbarPopup.push(
          <div key={"Popup-Linkbar-Link-Index-"+ii} ref={elem => this.linkbarPopupLinkRefs[ii] = elem} className={styles["Link"]}>
            <NavLink className={({ isActive }) => (isActive ? styles["Selected-Link"] : styles["Not-Selected-Link"])+extraClasses} to={route}>{text}</NavLink>
          </div>
        );
    });

    //append admin links
    if(admin){
      adminLinks.forEach((route, ii) => {
        let text = `[${route}]`;
        if(route in values.routes){
          text = values.routes[route].text;
        } else {
          text = "Admin";
        }
  
        linkbarHorizontal.push(
            <div key={"Hor-Linkbar-Admin-Link-Index-"+ii} ref={elem => this.linkbarHorizontalLinkRefs[values.linkbar.length + ii] = elem} className={styles["Link"]}>
              <NavLink className={({ isActive }) => (isActive ? styles["Selected-Admin-Link"] : styles["Not-Selected-Admin-Link"])} to={route}>{text}</NavLink>
            </div>
          );
        linkbarPopup.push(
            <div key={"Popup-Linkbar-Admin-Link-Index-"+ii} ref={elem => this.linkbarPopupLinkRefs[values.linkbar.length +ii] = elem} className={styles["Link"]}>
              <NavLink className={({ isActive }) => (isActive ? styles["Selected-Admin-Link"] : styles["Not-Selected-Admin-Link"])} to={route}>{text}</NavLink>
            </div>
          );
      });  
    }

    const pageTitle = this.props.pageTitlePrefix ? this.props.pageTitlePrefix : "Home";

    return (
      <div className={styles["Root"]}>
        <div className={styles["LinkOverflowDiv"]}  ref={elem => this.linkOverflowDiv = elem}>
            <div ref={elem => this.linkbarHorizontalTop = elem} className={styles["Horizontal"]}>
              {linkbarHorizontal}
            </div>
        </div>
        <div className={styles["ExpandButtonRoot"]} ref={elem => this.linkbarShowMore = elem} onClick={() => this.showMorePopup()}>
          {pageTitle}
          <i className={styles["Menu-Button"]+" demo-icon icon-menu"}></i>
        </div>

        <div onClick={() => this.hideMorePopup()} ref={elem => this.linkbarPopupBackground = elem} className={styles["PopupBackground"]}>
          <div ref={elem => this.linkbarPopup = elem} className={styles["Popup"]}>
            <div><img className={styles["Airport-Logo"]} src={process.env.REACT_APP_FILE_PREFIX+"/moraine-airpark-logo.jpg"} alt="Moraine Airport Logo" /></div>
            {linkbarPopup}
          </div>
        </div>
      </div>
    );
  }
}

export default Linkbar;
