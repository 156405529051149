import styles from './Slideshow.module.css';
import { Link } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import useSwipe from "./Swipe";

const Z_TOP = 2;
const Z_MIDDLE = 1;
const Z_BOTTOM = 0;

const X_SLIDE_LEFT = "-100%";
const X_SLIDE_RIGHT = "100%";

const TRANSITION_SLIDE_LEFT = 0;
const TRANSITION_SLIDE_RIGHT = 1;
const TRANSITION_FADE = 2;

function Slideshow(props){
  const [showing, setShowing] = useState(0);
  const [slides, setSlides] = useState([]);
  const [error, setError] = useState(null);
  const [autoPlay, setAutoPlay] = useState(true);

  const rawContent = props.content; 

  // Initial load
  useEffect(() => {
    // console.log("Loading slides");
    try{
      const content = JSON.parse(rawContent);

      // filter out slides that shouldn't be showing
      let now = new Date();
      let loadedSlides = content.slides
        .filter( (ss) => ! ("expires" in ss && (new Date(ss["expires"]) <= now)) )
        .filter( (ss) => ! ("begins" in ss && (new Date(ss["begins"]) > now)) )
        .filter( (ss) => ! ("disabled" in ss && (ss["disabled"] === "true")) );

      // append CSS attribute info to the slides array
      loadedSlides.forEach((slide,ii) => {
        slide.visibility = (ii === 0 ? "visible" : "hidden"); //we hide all slides except the first so they dont flicker during load
        slide.opacity = 1.0;
        slide.left = 0;
        slide.zIndex = (ii === 0 ? Z_TOP : Z_BOTTOM);
      });
      setSlides( () => loadedSlides );
    }
    catch(error){
      console.error(error);
      setError(error);
    }
  }, [rawContent]);


  // callback for changing slides
  let showSlide = useCallback( (showII, transition) => {
    // console.log(`Change from ${showing}->${showII}`);

    // do nothing if we arent changing slides
    if(showing === showII){
      return ;
    }

    // do animation
    setSlides((xx) => {
      let yy = [...xx];
      yy[showII].zIndex = Z_MIDDLE;
      yy[showII].visibility = "visible";
      if(transition === TRANSITION_FADE){
        yy[showing].opacity = 0;
      }
      else if(transition === TRANSITION_SLIDE_LEFT){
        yy[showing].left = X_SLIDE_LEFT;
      }
      else if(transition === TRANSITION_SLIDE_RIGHT){
        yy[showing].left = X_SLIDE_RIGHT;
      }
      return yy;
    });

    // after animation, update slide state
    setTimeout(
      () => {
        setSlides((xx) => {
          let yy = [...xx];
          yy[showII].zIndex = Z_TOP;
          yy[showing].zIndex = Z_BOTTOM;
          yy[showing].opacity = 1.0;
          yy[showing].left = 0;
          return yy;
        });
        setShowing(showII);
      }, 500
    );
  }, [showing]);

  // setup swipe logic
  const swipeHandlers = useSwipe({ 
    onSwipedLeft: () => {
      showSlide( Math.min(showing + 1, slides.length-1), TRANSITION_SLIDE_LEFT);
      setAutoPlay(false);
    },
    onSwipedRight: () => {
      showSlide( Math.max(showing - 1, 0), TRANSITION_SLIDE_RIGHT);
      setAutoPlay(false);
    }
  });

  // auto play - this fires every time a new slide is shown
  useEffect(() => {
    // if slides hasn't loaded yet, do nothing
    if(!slides || slides.length === 0){
      return ;
    }
    
    // if autoplay has been stopped, do nothing
    if(!autoPlay){
      return ;
    }

    const duration = ("duration" in slides[showing]) ? slides[showing].duration : 8;

    const timerID = setTimeout(
      () => {
        const nextSlide = (showing + 1) % slides.length;
        if(autoPlay){
          showSlide(nextSlide, TRANSITION_FADE);
        }
      }, duration * 1000);
    return () => {
      clearTimeout(timerID);
    }
  }, [showing, autoPlay, showSlide, slides])


  if(error){
      return <div className={styles["Error"]}>{error.toString()}</div>;
  }

  return (
    <div className={styles["Root"]}>
     {slides && slides.map((slide, ii) => {

        let backgroundPosition = ("backgroundPosition" in slide ? slide["backgroundPosition"] : "center");
        let showCaptionBox = (slide["caption"] ? "" : " "+styles["Empty-Caption-Box"]);
        let showDotBox = (slides.length > 1 ? "" : "none");

        let captionAndDotBackground = ("background" in slide ? slide["background"] : "#000B");
        let textColor = ("color" in slide ? slide["color"] : "#FFF");
        let dotColor = ("dotColor" in slide ? slide["dotColor"] : "#AAA");
        let activeDotColor = ("dotActiveColor" in slide ? slide["dotActiveColor"] : "#FFF");

        let caption = slide["caption"];
        if("internal-link" in slide){
          caption = <Link to={slide["internal-link"]}>{caption}</Link>
        }
        else if("external-link" in slide){
          caption = <a target="_blank" rel="noreferrer" href={slide["external-link"]}>{caption}</a>
        }

        return (
          <div key={"Slide-Root-"+ii} className={styles["Slide-Root"]} //ref={elem => this.slideRefs[ii] = elem}
            {...swipeHandlers}
            style={{
              opacity: slide.opacity,
              visibility: slide.visibility,
              zIndex: slide.zIndex,
              left: slide.left,
              backgroundImage:`url(${process.env.REACT_APP_FILE_PREFIX + "/" + slide["src"]})`,
              backgroundPosition: `${backgroundPosition}`,
              backgroundRepeat:"no-repeat" }}>
            <div className={styles["Center"]}>
              <div></div>
              <div className={styles["Caption-Row"]}>
                <div className={styles["Caption-Box"] + showCaptionBox}>
                  <div className={styles["Caption-Background"]} style={{background: `${captionAndDotBackground}`}}></div>
                  <div className={styles["Caption-Text"]} style={{color: `${textColor}`}}>
                    {caption}
                  </div>
                </div>
                <div className={styles["Dot-Row"]}>
                  <div className={styles["Dot-Box"]} style={{display: `${showDotBox}`}}>
                    <div className={styles["Dot-Background"]} style={{background: `${captionAndDotBackground}`}}></div>
                    <div className={styles["Dot-Text"]}>
                      {
                        slides.map((_, jj) => {
                          let color = (ii===jj) ? activeDotColor : dotColor;
                          return (
                            <div key={"clickdot"+jj} className={styles["Dot-Click-Area"]} onClick={()=>{showSlide(jj, TRANSITION_FADE); setAutoPlay(false)}}>
                              <div className={styles["Dot"]} style={{background: `${color}`}}></div>
                            </div>
                            )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })
    }
    </div>
  );

}

export default Slideshow;
