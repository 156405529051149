import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import styles from './PageTable.module.css';
import React, { useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Popover from '@mui/material/Popover';

function PageTable(props){
  const [addEnabled, setAddEnabled] = useState(false);
  const [popAnchor, setPopAnchor] = useState(null);
  const [popText, setPopText] = useState("");

  const newRouteRef = useRef(null);
  const newTextRef = useRef(null);
  const newPageRef = useRef(null);

  const routes = props.values.routes;

  const updateAddEnabled = useCallback(() => {
    setAddEnabled(
      ! (newRouteRef.current.value in routes) && 
      newPageRef.current.value !== ""
    );
  }, [setAddEnabled, routes]);

  return (
    <div>
      <table className={styles["AdminTableTop"]}>
      <tbody>
        <tr key="row-header">
          <td className={styles["AdminTableHeader"]}>
            Route
            <i className="demo-icon icon-help-circled" onClick={(ee) => {setPopText("The route is the last part of the web browser's URL. A page is displayed when the user has navigated to it's route."); setPopAnchor(ee.currentTarget);}} />
          </td>
          <td className={styles["AdminTableHeader"]}>
            Title
            <i className="demo-icon icon-help-circled" onClick={(ee) => {setPopText("A human friendly title for the page. If this page has been added to the linkbar, the title is the link text."); setPopAnchor(ee.currentTarget);}} />
          </td>
          <td className={styles["AdminTableHeader"]}>
            File
            <i className="demo-icon icon-help-circled" onClick={(ee) => {setPopText("The file on disk holding a page's contents. The extension is used to determine the page type."); setPopAnchor(ee.currentTarget);}} />
          </td>
          <td className={styles["AdminTableHeader"]}>&nbsp;</td>
        </tr>
        {
          Object.entries(routes).sort().map(([route,value], ii) => (
            <tr key={"route"+ii}>
              <td className={styles["AdminTableCell"]}>
                <TextField 
                  id="route-route" 
                  label=""
                  onChange={
                    (ee) => {
                      props.setValues(
                        (xx) => {
                          let yy = {...xx};
                          const vv = yy["routes"][route];
                          delete yy["routes"][route];
                          yy["routes"][ee.target.value] = vv;
                          return yy;
                        }
                      )
                    }
                  } 
                  variant="outlined" 
                  size="small" 
                  value={route} />
              </td>
              <td className={styles["AdminTableCell"]}>
                <TextField 
                  id="route-text" 
                  label=""
                  onChange={
                    (ee) => {
                      props.setValues(
                        (xx) => {
                          let yy = {...xx};
                          yy["routes"][route]["text"] = ee.target.value;
                          return yy;
                        }
                      )
                    }
                  } 
                  variant="outlined" 
                  size="small" 
                  value={value["text"]} />
              </td>
              <td className={styles["AdminTableCell"]}>
                <TextField 
                  id="route-page" 
                  label=""
                  onChange={
                    (ee) => {
                      props.setValues(
                        (xx) => {
                          let yy = {...xx};
                          yy["routes"][route]["page"] = ee.target.value;
                          return yy;
                        }
                      )
                    }
                  } 
                  variant="outlined" 
                  size="small" 
                  value={value["page"]} />
              </td>
              <td className={styles["AdminTableCell"]}>
                <div className={styles["PageButtons"]}>
                  <div>
                    <Button 
                      id="del-route"
                      label=""
                      className={styles["MuiShrinkableButton"]}
                      onClick={
                        (ee) => {
                          props.setValues(
                            (xx) => {
                              let yy = {...xx};
                              delete yy["routes"][route];
                              return yy;
                            }
                          )
                        }
                      } 
                      variant="outlined">Del</Button>
                  </div>
                  <div>
                    <Button 
                      id="go-route"
                      label=""
                      className={styles["MuiShrinkableButton"]}
                      component={Link}
                      to={"/"+route}
                      variant="outlined">Go</Button>
                  </div>
                </div>
              </td>
            </tr>
          ))
        }
        <tr key="addroute" className={styles["AddRow"]}>
          <td className={styles["AdminTableCell"]}>
            <TextField 
              id="route-route" 
              inputRef={newRouteRef}
              onChange={() => {
                // automatically determine the file name. It will just
                // route => route.extension (default extension is md)
                const newRoute = newRouteRef.current.value.split(" ").join("").split(".").join(""); // dots and spaces not allowed in route
                const newPage = newPageRef.current.value;
                let parts = newPage.split(".");
                if(parts.length === 1){
                  parts.push("md");
                }
                parts[0] = newRoute;
                newRouteRef.current.value = newRoute;
                newPageRef.current.value = newRoute === "" ? "" : parts.join(".");
                updateAddEnabled();
              }}
              label=""
              variant="outlined" 
              size="small" />
          </td>
          <td className={styles["AdminTableCell"]}>
            <TextField 
              id="route-text" 
              inputRef={newTextRef}
              onChange={updateAddEnabled}
              label=""
              variant="outlined" 
              size="small" />
          </td>
          <td className={styles["AdminTableCell"]}>
            <TextField 
              id="route-page" 
              inputRef={newPageRef}
              onChange={updateAddEnabled}
              label=""
              variant="outlined" 
              size="small" />
          </td>
          <td className={styles["AdminTableCell"]}>
            <Button 
              id="del-route"
              label=""
              onClick={!addEnabled ? ()=>{} :
                (ee) => {
                  props.setValues(
                    (xx) => {
                      let yy = {...xx};
                      yy["routes"][newRouteRef.current.value] = {
                        "text" : newTextRef.current.value,
                        "page" : newPageRef.current.value
                      };
                      newRouteRef.current.value = "";
                      newTextRef.current.value = "";
                      newPageRef.current.value = "";
                      setAddEnabled(false);
                      return yy;
                    }
                  )
                }
              } 
              variant={addEnabled ? "contained" : "outlined"} disabled={!addEnabled}>Add</Button>
          </td>
        </tr>
      </tbody>
    </table>
    <Popover id="popover-name-help" open={popAnchor !== null} anchorEl={popAnchor}
      onClose={()=>setPopAnchor(null)} anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}>
      <div className={styles["Popup"]}>
        {popText}
      </div>
    </Popover>
  </div>
  );
}

export default PageTable;
